import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from '../AdminCountAuthFirestore/authService';
import { FaGift } from 'react-icons/fa';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion';
import styles from './ClaimReward.module.css';

export const ClaimRewardBox = () => {
    const [totalAddedMoney, setTotalAddedMoney] = useState(0);
    const [balance, setBalance] = useState(0);
    const [isEligible, setIsEligible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isBoxVisible, setIsBoxVisible] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("")
    const db = getFirestore();
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            if (!currentUser) return;

            const userRef = doc(db, "users", currentUser.uid);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
                const userData = userSnap.data();
                setTotalAddedMoney(userData.totalAddedMoney || 0);
                setBalance(userData.balance || 0);
                setIsEligible(userData.totalAddedMoney >= 500);
            }
            setLoading(false);
        };

        fetchUserData();
    }, [db, currentUser]);

    const handleClaimReward = async () => {
        if (!currentUser || !isEligible) return;

        try {
            const userRef = doc(db, "users", currentUser.uid);
            const updatedTotalAddedMoney = totalAddedMoney - 500;

            // Update balance by adding a fixed bonus of 100 Taka and reset the total added money
            await updateDoc(userRef, {
                balance: balance + 100,  // Set fixed bonus to 100
                totalAddedMoney: updatedTotalAddedMoney
            });

            setBalance(balance + 100);
            setTotalAddedMoney(updatedTotalAddedMoney);
            setIsEligible(updatedTotalAddedMoney >= 500);

            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 3000);
            setMessage('Successfully added 100 Taka to your balance');
            setMessageType("success");
        } catch (error) {
            console.error('Error claiming reward:', error);
        }
    };

    const progressPercentage = (totalAddedMoney / 500) * 100;

    if (loading) {
        return <p>Loading...</p>;
    }

    const handleCloseMessage = () => {
        setMessage(null);
    };

    return (
        <div className={styles.rewardBoxContainer}>
            <motion.div
                className={styles.toggleButton}
                onClick={() => setIsBoxVisible(!isBoxVisible)}
                initial={{ y: 0 }}
                animate={{ y: [-5, 0, -5] }}
                transition={{ repeat: Infinity, duration: 1, ease: "easeInOut" }}
            >
                <motion.div
                    className={styles.jumpingGift}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <FaGift />
                </motion.div>
            </motion.div>

            {isBoxVisible && (
                <motion.div
                    className={styles.rewardBox}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                >
                    <h3>Claim Your Reward</h3>
                    <p>Total Added Money: {totalAddedMoney}</p>

                    <div className={styles.progressBarContainer}>
                        <motion.div
                            className={styles.progressBar}
                            style={{ width: `${progressPercentage}%` }}
                            initial={{ width: 0 }}
                            animate={{ width: `${progressPercentage}%` }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                        />
                    </div>

                    <p>Add Total 500 Taka to </p>
                    <p>claim 100 Taka bonus!</p>

                    {isEligible ? (
                        <button className={styles.claimButton} onClick={handleClaimReward}>
                            Claim Reward
                        </button>
                    ) : (
                        <p>Keep adding to claim a reward!</p>
                    )}
                    {message && (
                        <div className={`popup ${messageType} visible`}>
                            <span className={styles.message}>{message}</span>
                            <button className="close-btn" onClick={handleCloseMessage}>x</button>
                        </div>
                    )}
                </motion.div>
            )}

            {showConfetti && <Confetti />}
        </div>
    );
};
