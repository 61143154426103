import React from 'react';
import Navbar from '../Navbar/bar';
import { Link } from 'react-router-dom';
import styles from './PricingPage.module.css';

export const PricingPage = () => {
    const pricingData = [
        {
            title: "Monthly Packages",
            prices: [
                { speed: "100mbps", price: 160 },
                { speed: "60mbps", price: 130 },
                { speed: "50mbps", price: 100 },
                { speed: "40mbps", price: 90 },
                { speed: "30mbps", price: 70 },
                { speed: "20mbps", price: 50 }
            ]
        },
        {
            title: "15-Day Packages",
            prices: [
                { speed: "100mbps", price: 130 },
                { speed: "60mbps", price: 110 },
                { speed: "50mbps", price: 80 },
                { speed: "40mbps", price: 70 },
                { speed: "30mbps", price: 60 },
                { speed: "20mbps", price: 40 }
            ]
        },
        {
            title: "Weekly Packages",
            prices: [
                { speed: "100mbps", price: 100 },
                { speed: "60mbps", price: 90 },
                { speed: "50mbps", price: 70 },
                { speed: "40mbps", price: 60 },
                { speed: "30mbps", price: 50 },
                { speed: "20mbps", price: 30 }
            ]
        }
    ];

    return (
        <div>
            <Navbar />
            <div className={styles.mainContent}>
                <div className={styles.bonusNews}>
                    <Link to="/login">
                        🎉 Unlock Your 100 Taka Bonus! 🎉 Add a total of 500 Taka to your balance and receive an extra 100 Taka for free!
                        <p>If you consider this bonus as a discount, you'll effectively be paying just 126.67 Taka for 100 Mbps per month!</p>
                    </Link>
                </div>
                <div className={styles.pricingContainer}>
                    {pricingData.map((packageData, index) => (
                        <div key={index} className={styles.pricingCard}>
                            <h3 className={styles.cardTitle}>{packageData.title}</h3>
                            <ul className={styles.pricingList}>
                                {packageData.prices.map((item, idx) => (
                                    <li key={idx} className={styles.pricingItem}>
                                        <span>{item.speed}</span>
                                        <span>{item.price} Taka</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PricingPage;
