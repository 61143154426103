import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css'; // Import the module CSS

export const Navbar = () => {
  

  
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}><a href="/">SPEED</a></div>
      <ul className={styles.menu}>
        <li><Link to="/tools">Tools</Link></li>
        <li><Link to="/tutorials">Tutorials</Link></li>
        <li><Link to="/pricing">Pricing</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/reviews">Reviews</Link></li>
      </ul>
      <div className={styles.buttons}>
        <Link to="/login"><button>Login</button></Link>
        <Link to="/signup"><button>Signup</button></Link>
      </div>
    </nav>
  );
};

export default Navbar;
