import { useState } from "react";
import { auth, db } from "../../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import styles from './ForgotPassword.module.css'; // Import the module CSS

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");

    const checkEmailExists = async (email) => {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault(); // Prevent form submission
        if (!email) {
            setMessage("Email is required.");
            setMessageType("error");
            setTimeout(() => setMessage(""), 1500);
            return;
        }

        const emailExists = await checkEmailExists(email);

        if (!emailExists) {
            setMessage("Email does not exist.");
            setMessageType("error");
            setTimeout(() => setMessage(""), 1500);
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage("Password reset email sent!");
            setMessageType("success");
        } catch (error) {
            setMessage("Error: " + error.message);
            setMessageType("error");
        }

        setTimeout(() => setMessage(""), 1500);
    };

    return (
        <div className={styles.container}>
            <div className={styles.forgotPassword}>
                <h1>Forgot Password</h1>
                <form onSubmit={handleResetPassword}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Reset Password</button>
                </form>
                <div className={styles.linkContainer}>
                    <Link to="/login">Back to Login</Link>
                </div>
                {message && <p className={`${styles.message} ${styles[messageType]}`}>{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPassword;
