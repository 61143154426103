import { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { auth, googleprovider, db } from "../../config/firebase";
import { FaGoogle, FaArrowLeft } from 'react-icons/fa';
import {
    createUserWithEmailAndPassword,
    signInWithPopup,
    signOut,
    onAuthStateChanged,
    sendEmailVerification
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styles from './Signup.module.css';

export const Signup = () => {
    const [signupEmail, setSignupEmail] = useState("");
    const [signupPassword, setSignupPassword] = useState("");
    const [username, setUsername] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showRequirements, setShowRequirements] = useState(false);

    // Password requirements state
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        hasLowerCase: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await user.reload();
                setIsLoggedIn(user.emailVerified);
                if (!user.emailVerified) {
                    setMessage("Please verify your email before logging in.");
                    setMessageType("error");
                }
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (isLoggedIn) {
        return <Navigate to="/dashboard" />;
    }

    const saveUserDetails = async (uid, username, email) => {
        const userDocRef = doc(db, "users", uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid,
                username,
                email,
                balance: 0
            });
        }
    };

    const handleSignup = async () => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, signupEmail, signupPassword);
            const user = userCredential.user;
            await saveUserDetails(user.uid, username, signupEmail);
            await sendEmailVerification(user);
            setMessage("Signup successful! Verification email sent. Please verify your email.");
            setMessageType("success");
            await signOut(auth);
        } catch (err) {
            setMessage(`Signup failed: ${err.message}`);
            setMessageType("error");
        }
    };

    const handleGoogleSignup = async () => {
        try {
            const userCredential = await signInWithPopup(auth, googleprovider);
            const user = userCredential.user;
            await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
            setMessage("Signup with Google successful!");
            setMessageType("success");
        } catch (err) {
            setMessage(`Google signup failed: ${err.message}`);
            setMessageType("error");
        }
    };

    const handlePasswordFocus = () => setShowRequirements(true);
    const handlePasswordBlur = () => setShowRequirements(false);

    const validatePassword = (password) => {
        setPasswordRequirements({
            minLength: password.length >= 6,
            hasLowerCase: /[a-z]/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[@#!%^&]/.test(password),
        });
    };

    return (
        <div>
            <button className={styles.backButton}><Link to="/"><FaArrowLeft /> Back</Link></button>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2>Signup</h2>
                    <div className={styles.inputContainer}>
                        <input
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            placeholder="Email"
                            value={signupEmail}
                            onChange={(e) => setSignupEmail(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            type="password"
                            placeholder="Password"
                            value={signupPassword}
                            onFocus={handlePasswordFocus}
                            onBlur={handlePasswordBlur}
                            onChange={(e) => {
                                setSignupPassword(e.target.value);
                                validatePassword(e.target.value);
                            }}
                        />
                    </div>

                    {/* Password requirements */}
                    {showRequirements && (
                        <div className={styles.passwordRequirements}>
                            <span className={passwordRequirements.minLength ? styles.met : ''}>✔ At least 6 characters</span>
                            <span className={passwordRequirements.hasLowerCase ? styles.met : ''}>✔ Lowercase letter</span>
                            <span className={passwordRequirements.hasUpperCase ? styles.met : ''}>✔ Uppercase letter</span>
                            <span className={passwordRequirements.hasNumber ? styles.met : ''}>✔ Use Numbers</span>
                            <span className={passwordRequirements.hasSpecialChar ? styles.met : ''}>✔ Special characters like @#!%^& etc</span>
                        </div>
                    )}

                    <button className={styles.signupButton} onClick={handleSignup}>Signup</button>
                    <button className={styles.buttonGoogleButton} onClick={handleGoogleSignup}>
                        <FaGoogle className={styles.googleIcon} /> Signup with Google
                    </button>
                    {message && <div className={`${styles.message} ${styles[messageType]}`}>{message}</div>}
                    <Link to="/login" className={styles.signinLink}>Already have an account? Login</Link>
                </div>
            </div>
        </div>
    );
};

export default Signup;
