import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaPowerOff } from 'react-icons/fa'; // Import power icon
import { Navigate, Link } from "react-router-dom";
import axios from "axios";
import {
  addProxyToFirestore,
  updateProxyInFirestore,
  removeProxyFromFirestore,
} from "./AdminCountAuthFirestore/firestoreService";
import { handleLogout } from "./AdminCountAuthFirestore/authService";
import { auth as firebaseAuth, db, onAuthStateChanged } from "../../config/firebase";
import {
  doc,
  onSnapshot,
  collection,
  addDoc,
  getDoc,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getCountFromServer // Import getCountFromServer
} from "firebase/firestore";
import './Dashboard.css';
import CountdownTimer from './AdminCountAuthFirestore/CountdownTimer';
import { ClaimRewardBox } from './RewardBox/ClaimRewardBox';
// import { Alerts } from './Alerts/Alerts';
import { QuotaResetTimer } from './QuotaCount/QuotaResetTimer';
import { ReviewPopup } from './ReviewPopup/ReviewPopup'


export const Dashboard = () => {
  // State Variables
  const [password, setPassword] = useState("");
  const [speed, setSpeed] = useState("");
  const [expiry, setExpiry] = useState("30");
  const [proxyList, setProxyList] = useState([]);
  const [userId, setUserId] = useState("");
  const [balance, setBalance] = useState(0);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Only for generate/regenerate
  const [proxyCount, setProxyCount] = useState(0); // Total proxy count
  const [price, setPrice] = useState(0);
  const [proxyPage, setProxyPage] = useState(0);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [displayUsername, setDisplayUsername] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  const [currentRegeneratingProxyId, setCurrentRegeneratingProxyId] = useState(null);
  const [selectedRegenerateIP, setSelectedRegenerateIP] = useState("");
  const debounceTimeout = useRef(null);
  const [proxyCooldowns, setProxyCooldowns] = useState({});
  const [availableIPs, setAvailableIPs] = useState([]);
  const [selectedIP, setSelectedIP] = useState("");

  const proxiesPerPage = 20; // Adjust as needed
  const historyRef = useRef([]); // Use ref to track cursors without causing re-renders
  const lastVisibleRef = useRef(null); // Use ref for lastVisible to prevent useEffect re-runs
  const [hasNextPage, setHasNextPage] = useState(false); // To manage "Next" button
  const [searchTerm, setSearchTerm] = useState(""); // Add this line to your state management


  // --------------------------
  // 1. Fetch User Details with onSnapshot
  // --------------------------
  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "users", userId);
    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();

        setDisplayUsername(userData.username);
        setBalance(userData.balance);
      }
    }, (error) => {
      console.error("Failed to fetch user details:", error);
      setMessage("Failed to fetch user details. Please try again later.");
      setMessageType("error");
    });

    return () => {

      unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    // Function to get a new token when the component loads
    const fetchToken = async (user) => {
      if (user) {
        const newToken = await user.getIdToken(true); // 'true' forces token refresh
        localStorage.setItem("jwtToken", newToken); // Update token in localStorage
      }
    };

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        fetchToken(user);
      } else {
        console.error("User is not authenticated");
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  // --------------------------
  // 2. Fetch Total Proxy Count
  // --------------------------
  useEffect(() => {
    const fetchProxyCount = async () => {
      if (!userId) return;


      try {
        const proxiesCollectionRef = collection(db, "users", userId, "proxies");
        const countQuery = query(proxiesCollectionRef);
        const snapshot = await getCountFromServer(countQuery);
        setProxyCount(snapshot.data().count);

      } catch (error) {
        console.error("Failed to fetch proxy count:", error);
      }
    };

    fetchProxyCount();
  }, [userId]);

  // --------------------------
  // 3. Fetch Proxies with Pagination
  // --------------------------
  const fetchProxies = useCallback(async () => {
    if (!userId) return;


    // Do not set isLoading here to avoid showing loader on page change

    try {
      const proxiesCollectionRef = collection(db, "users", userId, "proxies");
      let proxiesQuery = query(
        proxiesCollectionRef,
        orderBy("generatedDate", "desc"),
        limit(proxiesPerPage)
      );

      // If not the first page, use startAfter with the cursor from historyRef
      if (proxyPage > 0 && historyRef.current[proxyPage - 1]) {

        proxiesQuery = query(
          proxiesCollectionRef,
          orderBy("generatedDate", "desc"),
          startAfter(historyRef.current[proxyPage - 1]),
          limit(proxiesPerPage)
        );
      }

      const snapshot = await getDocs(proxiesQuery);
      const proxies = [];
      const cooldowns = {};

      snapshot.forEach((docSnap) => {
        const proxy = { id: docSnap.id, ...docSnap.data() };
        proxies.push(proxy);

        if (proxy.countdownEnd && new Date(proxy.countdownEnd) > new Date()) {
          cooldowns[proxy.id] = true;
        } else {
          cooldowns[proxy.id] = false;
        }
      });

      setProxyList(proxies);
      setProxyCooldowns(cooldowns);

      const last = snapshot.docs[snapshot.docs.length - 1];
      if (last) {

        if (historyRef.current.length === proxyPage) {
          // Only add to history if it's a new page
          historyRef.current.push(last);

        } else {
          // Update history for the current page if navigating back
          historyRef.current[proxyPage] = last;

        }
        lastVisibleRef.current = last;
        // Determine if there is a next page
        setHasNextPage(snapshot.size === proxiesPerPage);
      } else {

        setHasNextPage(false);
      }
    } catch (error) {
      if (error.code === 'resource-exhausted') {
        setMessageType("error");
        setMessage(<QuotaResetTimer />);

      } else {
        setMessage("Failed to fetch proxy list. Please try again later.");
        setMessageType("error");
      }
    }
  }, [userId, proxyPage, proxiesPerPage]);

  // Fetch Proxies when userId or proxyPage changes
  useEffect(() => {
    if (userId) {
      fetchProxies();
    }
  }, [userId, proxyPage, fetchProxies]);

  // --------------------------
  // 4. Check if Username Exists
  // --------------------------
  const checkUsernameExists = async (username) => {

    const q = query(collection(db, "users", userId, "proxies"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    const exists = !querySnapshot.empty;

    return exists;
  };

  // --------------------------
  // 5. Auth State Change Listener
  // --------------------------
  useEffect(() => {

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {

        setUserId(user.uid);
      } else {

        setUserId("");
        setIsLoggedOut(true);
      }
      setIsLoading(false);
    });

    return () => {

      unsubscribe();
    };
  }, []);

  // --------------------------
  // 6. Fetch Available IPs from Backend
  // --------------------------
  useEffect(() => {
    const fetchAvailableIPs = async () => {
      try {
        const response = await axios.get("https://proxygen-api-v1.onrender.com/get_available_ips");
        if (response.data.success) {
          setAvailableIPs(response.data.ips);
        } else {
          console.error("Failed to fetch available IPs:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching available IPs:", error);
      }
    };

    fetchAvailableIPs();
  }, []);

  // --------------------------
  // 7. Calculate Price Based on Speed and Expiry
  // --------------------------
  useEffect(() => {
    const speedMbps = parseInt(speed, 10);
    const expiryDays = parseInt(expiry, 10);
    let fixedPrice = 0;

    if (!isNaN(speedMbps)) {
      if (expiryDays === 30) {
        if (speedMbps === 100) fixedPrice = 160;
        else if (speedMbps === 60) fixedPrice = 130;
        else if (speedMbps === 50) fixedPrice = 100;
        else if (speedMbps === 40) fixedPrice = 90;
        else if (speedMbps === 30) fixedPrice = 70;
        else if (speedMbps === 20) fixedPrice = 50;
      } else if (expiryDays === 15) {
        if (speedMbps === 100) fixedPrice = 130;
        else if (speedMbps === 60) fixedPrice = 110;
        else if (speedMbps === 50) fixedPrice = 80;
        else if (speedMbps === 40) fixedPrice = 70;
        else if (speedMbps === 30) fixedPrice = 60;
        else if (speedMbps === 20) fixedPrice = 40;
      } else if (expiryDays === 7) {
        if (speedMbps === 100) fixedPrice = 100;
        else if (speedMbps === 60) fixedPrice = 90;
        else if (speedMbps === 50) fixedPrice = 70;
        else if (speedMbps === 40) fixedPrice = 60;
        else if (speedMbps === 30) fixedPrice = 50;
        else if (speedMbps === 20) fixedPrice = 30;
      }
    }


    setPrice(fixedPrice);
  }, [speed, expiry]);

  // --------------------------
  // 8. Handle Proxy Generation
  // --------------------------
  const lock = useRef(false);  // Move useRef to the top level of your component

const handleGenerateProxy = async () => {
    // Prevent multiple clicks when loading
    if (isRegenerating || isLoading || lock.current) return;

    setIsLoading(true); // Start loading and disable the button
    lock.current = true;  // Lock the request to avoid multiple clicks

    // Clear any existing debounce timeout (if user clicks rapidly)
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    const speedMbps = parseInt(speed, 10);
    const expiryDays = parseInt(expiry, 10);

    // Input Validation
  if (!usernameInput || !password || isNaN(speedMbps)) {
    setMessage("All fields are required.");
    setMessageType("error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput === password) {
    setMessage("Username and password cannot be the same.");
    setMessageType("error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput.includes(" ")) {
    setMessage("Username cannot contain spaces.");
    setMessageType("error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  // New Username Validation Logic
  const usernameLength = usernameInput.length;

  if (usernameLength < 4 || usernameLength > 12) {
    setMessage("Username ৪ থেকে ১২ অক্ষরের মধ্যে হতে হবে");
    setMessageType("error");
    setTimeout(() => setMessage(""), 10000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (/[^a-zA-Z0-9._-]/.test(usernameInput)) {
    setMessage("Username can only contain letters, numbers, dots, underscores, and hyphens.");
    setMessageType("error");
    setTimeout(() => setMessage(""), 15000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput.startsWith(".") || usernameInput.endsWith(".")) {
    setMessage("Username cannot start or end with a dot.");
    setMessageType("error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput.includes("@")) {
    setMessage("Username cannot contain an @.");
    setMessageType("error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }


    if (balance < price) {
      setMessage("Insufficient balance.");
      setMessageType("error");
      setTimeout(() => setMessage(""), 9000);
      setIsLoading(false);
      lock.current = false;  // Unlock after error
      return;
    }

    console.log("All validations passed. Proceeding to generate proxy.");
    const usernameExists = await checkUsernameExists(usernameInput);
    if (usernameExists) {
      setMessage("Username already exists. Please try something else.");
      setMessageType("error");
      setTimeout(() => setMessage(""), 9000);
      setIsLoading(false);
      lock.current = false;  // Unlock after error
      return;
    }

    const currentDate = new Date();
    const expiryDate = new Date(currentDate);
    expiryDate.setDate(currentDate.getDate() + expiryDays);

    try {
      // Include the userId in the request data
      const requestData = {
        username: usernameInput,
        password,
        speed: `${speedMbps}`,
        userId: userId,  // Add userId here
      };

      if (selectedIP) {
        requestData.ip = selectedIP;
      }

      console.log("Sending request to generate proxy:", requestData);

      // Retrieve the JWT from local storage
      const token = localStorage.getItem('jwtToken'); // Retrieve the token
      if (!token) {
          setMessage("User not authenticated. Please log out and log in again.");
          setMessageType("error");
          setIsLoading(false);
          lock.current = false;  // Unlock after error
          return;
      }


      // Set the token in the Authorization header
        const response = await axios.post("https://proxygen-api-v1.onrender.com/generate_proxy", requestData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

      if (response.data.success) {
        console.log("Proxy generated successfully:", response.data.proxy);
        const proxyData = {
          ...response.data.proxy,
          expiryDate: expiryDate.toISOString(),
          generatedDate: currentDate.toISOString(),
          status: 'active',
          countdownEnd: null
        };
        const newBalance = balance - price;
        await addProxyToFirestore(userId, proxyData, newBalance);
        setBalance(newBalance);
        setMessage("Proxy generated successfully!");
        setMessageType("success");
        // Fetch and refresh proxies after generation
        await fetchProxies();  // Ensure this is awaited so that the dashboard updates fully before re-enabling the button
        setUsernameInput(""); // Reset input
        setProxyCount(prevCount => prevCount + 1);
      } else {
        console.error("Proxy generation failed:", response.data.error);
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error("Error generating proxy:", error);
      setMessage(error.response?.data?.error || "Failed to generate proxy. Please try again later.");
      setMessageType("error");
    } finally {
      // Keep the button disabled until after the proxy list is fetched
      debounceTimeout.current = setTimeout(() => {
        setIsLoading(false);
        lock.current = false;   // Unlock the button after a delay
      }, 2000); // Add a slight delay after proxy is generated and dashboard is updated

      setTimeout(() => setMessage(""), 9000);  // Clear messages after 9 seconds
    }
};

  // --------------------------
  // 9. Handle Proxy Regeneration
  // --------------------------
  const handleRegenerateProxy = async (proxyId, oldIp, regenerateIP) => {
    if (isRegenerating || proxyCooldowns[proxyId]) return; // Prevent multiple regenerations

    // Check if the selected IP is the same as the old IP
    if (regenerateIP === oldIp) {
      setMessage("The selected IP is the same as the current IP. Please select a different IP.");
      setMessageType("error");
      setTimeout(() => setMessage(""), 9000);
      return; // Stop further execution
    }

    setIsRegenerating(true); // Indicate regeneration is in progress
    setIsLoading(true); // Show loading for regenerate

    try {
      const proxyDocRef = doc(db, "users", userId, "proxies", proxyId);
      const proxyDoc = await getDoc(proxyDocRef);

      if (!proxyDoc.exists()) {
        setMessage("Proxy not found.");
        setMessageType("error");
        setIsLoading(false);
        setIsRegenerating(false);
        setTimeout(() => setMessage(""), 9000);
        return;
      }

      const proxy = proxyDoc.data();

      if (proxy.status === 'expired') {
        setMessage("Expired Proxy. Generate a New Proxy");
        setMessageType("error");
        setIsLoading(false);
        setIsRegenerating(false);
        setTimeout(() => setMessage(""), 9000);
        return;
      }

      const { username, password, speed } = proxy;

      const requestData = {
        username,
        old_ip: oldIp,
        password,
        speed,
        userId: userId, // Ensure this is correctly defined
        ip: regenerateIP // If applicable
    };

      if (regenerateIP) { // Use the IP selected from the dashboard section
        requestData.ip = regenerateIP;
      }

      // Retrieve the JWT from local storage
      const token = localStorage.getItem('jwtToken'); // Retrieve the token
      if (!token) {
          setMessage("User not authenticated. Please logout and log in again.");
          setMessageType("error");
          setIsLoading(false);
          lock.current = false;  // Unlock after error
          return;
      }

      const response = await axios.post('https://proxygen-api-v1.onrender.com/regenerate_proxy', requestData, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });

      if (response.data.success) {

        const countdownDuration = 1.5e+6; // 25 minutes
        const countdownEnd = new Date(Date.now() + countdownDuration);

        const newProxyData = {
          ...response.data.proxy,
          ip: response.data.proxy.ip, // Update IP if needed
          countdownEnd: countdownEnd.toISOString()
        };

        await updateProxyInFirestore(userId, proxyId, newProxyData);

        // Update cooldown state for this proxy
        setProxyCooldowns((prevState) => ({
          ...prevState,
          [proxyId]: true,
        }));

        setMessage("Proxy regenerated successfully!");
        setMessageType("success");
        // Refresh proxies after regeneration
        fetchProxies();
      } else {
        console.error("Proxy regeneration failed:", response.data.error);
        setMessage(response.data.error || "Failed to regenerate proxy.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error regenerating proxy:", error);
      setMessage(error.response?.data?.error || "Failed to regenerate proxy. Please try again later.");
      setMessageType("error");
    } finally {
      setIsLoading(false);
      setIsRegenerating(false);
      setTimeout(() => setMessage(""), 9000);
    }
  };

  // --------------------------
  // 10. Handle Proxy Removal
  // --------------------------
  const handleRemoveProxy = async (proxyId) => {
    const proxy = proxyList.find(p => p.id === proxyId);
    if (!proxy) return;

    try {

      await removeProxyFromFirestore(userId, proxyId);

      const expiredProxyData = {
        ...proxy,
        status: 'expired'
      };
      await addDoc(collection(db, "users", userId, "expiredProxies"), expiredProxyData);

      setMessage("Proxy removed successfully!");
      setMessageType("success");
      // Refresh proxies after removal
      fetchProxies();
      // Update proxy count
      setProxyCount(prevCount => prevCount - 1);
    } catch (error) {
      console.error("Error removing proxy:", error);
      setMessage("Failed to remove proxy. Please try again later.");
      setMessageType("error");
    }

    setTimeout(() => setMessage(""), 9000);
  };

  // --------------------------
  // 11. Handle Countdown End
  // --------------------------
  const handleCountdownEnd = (proxyId) => {
    setProxyCooldowns((prevState) => ({
      ...prevState,
      [proxyId]: false, // Reset cooldown for this proxy
    }));

  };

  // --------------------------
  // 12. Pagination Handlers
  // --------------------------
  const handleNextPage = () => {
    if (hasNextPage) {

      setProxyPage(prevPage => prevPage + 1);
      // 'fetchProxies' will be triggered by 'proxyPage' change
    }
  };

  const handlePreviousPage = () => {
    if (proxyPage > 0) {

      setProxyPage(prevPage => prevPage - 1);
      // 'fetchProxies' will be triggered by 'proxyPage' change
    }
  };

  // --------------------------
  // 13. Helper Function to Get Last Segment of IP
  // --------------------------
  const getLastSegment = (ip) => {
    if (typeof ip !== "string" || !ip) return "";
    const segments = ip.split(".");
    return segments[segments.length - 1];
};


  // --------------------------
  // 14. Loading State for Generate/Regenerate Only
  // --------------------------
  // isLoading is already managed in handleGenerateProxy and handleRegenerateProxy

  // --------------------------
  // 15. Redirect if Logged Out
  // --------------------------
  if (isLoggedOut) {

    return <Navigate to="/" />;
  }

  const handleCloseMessage = () => {
    setMessage(null);
  };

  // --------------------------
  // 16. Displayed Proxies Based on Pagination
  // --------------------------
  const displayedProxies = proxyList;


  return (
    <div className="dashboard-container">
      {/* Navbar Section */}
      <div className="navbar">
      <Link to="/addmoney" className="add-money">
          Add Money
        </Link>
        <Link to="/" onClick={() => handleLogout(setIsLoggedOut)} className="logout">
        <FaPowerOff className="logout-icon" /> Logout
        </Link>
      </div>
      <div className="user-info">
        {/* <Alerts/> */}
        <ReviewPopup/>
      {message && (
  <div className={`popup ${messageType} visible`}>
    <span>{message}</span>
    <button className="close-btn" onClick={handleCloseMessage}>x</button>
  </div>
)}
  <div>
   <div className="claim-reward-box"><ClaimRewardBox/></div>
    {/* User Information Section */}
    <h1 className="username-animated">
        Welcome, <span className="username-dynamic">{displayUsername}</span>
    </h1>
    <p className="balance">
  {balance === 0 ? (
    <span>
      Balance is 0 Taka. Please <Link to="/addmoney">Add Money</Link>.
    </span>
  ) : (
    `Balance: ${balance} Taka`
  )}
</p>
    <p className="proxy-count">Total Proxies: {proxyCount}</p>
    {message && <p className={`message ${messageType}`}>{message}</p>}
    </div>
</div>
      {/* Input Section */}
      <div className="input-container">
  <input
    type="text"
    placeholder="Proxies Username"
    value={usernameInput}
    onChange={(e) => setUsernameInput(e.target.value)}
  />
  <input
    type="password"
    placeholder="Proxies Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    autoComplete="current-password"
  />

        {/* Speed Dropdown */}
        <select value={speed} onChange={(e) => setSpeed(e.target.value)}>
          <option value="">Select Speed (Mbps)</option>
          {[100, 60, 50, 40, 30, 20].map((value) => (
            <option key={value} value={value}>
              {value} Mbps
            </option>
          ))}
        </select>

        {/* Expiry Dropdown */}
        <select value={expiry} onChange={(e) => setExpiry(e.target.value)}>
          <option value="30">30 Days</option>
          <option value="15">15 Days</option>
          <option value="7">7 Days</option>
        </select>

        {/* IP Selection Dropdown */}
        <select value={selectedIP} onChange={(e) => setSelectedIP(e.target.value)}>
          <option value="">Select Server (Optional)</option>
          <option className="note" value="">Note: আপনার নির্বাচিত স্পিডের জন্য Recomended Server নির্বাচন করুন।</option>
          {availableIPs.length > 0 ? (
            availableIPs.map((ipObj) => (
              ipObj.ip ? (
                <option key={ipObj.ip} value={ipObj.ip}>
                  {`Server ${getLastSegment(ipObj.ip)}`} {ipObj.speed !== "N/A" && `- Recomended for ${ipObj.speed}`}
                </option>
              ) : null
            ))
          ) : (
            <option disabled>No servers available</option>
          )}
        </select>

        <p className="price">Price: {price} Taka</p>
        <button onClick={handleGenerateProxy} disabled={isLoading || isRegenerating}>
          {isLoading ? "Generating..." : "Generate Proxy"}
        </button>
      </div>
      <div>
        {/* Add search input for filtering by username */}
  <input className="search-input"
    type="text"
    placeholder="Search by Username"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)} // Update search term
  />
      </div>
      <div className="proxy-list">

  {displayedProxies
    .filter(proxy => proxy.username.toLowerCase().includes(searchTerm.toLowerCase())) // Filter by search term
    .sort((a, b) => {
      // Sort to show expired proxies first
      if (a.status === 'expired' && b.status !== 'expired') {
        return -1; // a comes first
      } else if (a.status !== 'expired' && b.status === 'expired') {
        return 1; // b comes first
      }
      return 0; // Keep the original order for others
    })
    .map((proxy) => (
      <div
        key={proxy.id}
        className={`proxy-item ${proxy.status === 'expired' ? 'expired' : ''}`}
        style={{
          border: proxy.status === 'expired' ? '2px solid red' : '1px solid black',
        }}
      >
        {/* Proxy Details */}
        <div className="proxy-details">
          <p>Status: <span style={{ color: proxy.status === 'expired' ? 'red' : 'green' }}>{proxy.status}</span></p>
          <p>IP: {proxy.ip}</p>
          <p>Port: {proxy.port}</p>
          <p>Username: {proxy.username}</p>
          <p>Password: {proxy.password}</p>
          <p>Speed: {proxy.speed} Mbps</p>
          <p>Generated: {new Date(proxy.generatedDate).toLocaleString()}</p>
          <p>Expiry: {new Date(proxy.expiryDate).toLocaleString()}</p>
        </div>

        <div className="proxy-actions">
          {proxy.status === 'expired' ? (
            <button onClick={() => handleRemoveProxy(proxy.id)} className="remove-button">
              Remove
            </button>
          ) : (
            <>
              <button
                onClick={() => {
                  if (currentRegeneratingProxyId === proxy.id) {
                    // Collapse if already open
                    setCurrentRegeneratingProxyId(null);
                    setSelectedRegenerateIP("");
                  } else {
                    // Open regeneration section for this proxy
                    setCurrentRegeneratingProxyId(proxy.id);
                    setSelectedRegenerateIP("");
                  }
                }}
                disabled={isRegenerating || proxyCooldowns[proxy.id]}
                className="regenerate-button"
              >
                {currentRegeneratingProxyId === proxy.id ? null : "Regenerate"}
              </button>
              {proxy.countdownEnd && (
                <CountdownTimer
                  countdownEnd={proxy.countdownEnd}
                  onCountdownEnd={() => handleCountdownEnd(proxy.id)}
                />
              )}
            </>
          )}
        </div>

        {/* Regeneration Section */}
        {currentRegeneratingProxyId === proxy.id && (
          <div className="regeneration-section">
            <p>Please disconnect the proxy from your device before regenerating.</p>
            <label htmlFor={`ip-select-${proxy.id}`}>Select Server (Optional):</label>
            <select
              id={`ip-select-${proxy.id}`}
              value={selectedRegenerateIP}
              onChange={(e) => setSelectedRegenerateIP(e.target.value)}
            >
              <option value="">Auto-select server</option>
              {availableIPs.length > 0 ? (
                availableIPs.map((ipObj) => (
                  ipObj.ip ? (
                    <option key={ipObj.ip} value={ipObj.ip}>
                      Server {getLastSegment(ipObj.ip)} {ipObj.speed !== "N/A" && `- Best for ${ipObj.speed}`}
                    </option>
                  ) : null
                ))
              ) : (
                <option disabled>No servers available</option>
              )}

            </select>
            <div className="regeneration-buttons">
              <button
                onClick={() => handleRegenerateProxy(proxy.id, proxy.ip, selectedRegenerateIP)}
                disabled={isLoading || isRegenerating}
              >
                {isLoading ? "Regenerating..." : "Confirm Regenerate"}
              </button>
              <button
                onClick={() => {
                  setCurrentRegeneratingProxyId(null);
                  setSelectedRegenerateIP("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    ))}
</div>


      {/* Pagination Buttons */}
      <div className="nav-buttons">
        <button onClick={handlePreviousPage} disabled={proxyPage === 0}>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={!hasNextPage}>
          Next
        </button>
      </div>
    </div>
  );
};
