import React from 'react';
import Navbar from '../Navbar/bar';
import styles from './Tools.module.css'; // Import the CSS module

export const Tools = () => {

  return (
    <div>
      <Navbar />
      <div className={styles.toolsContainer}>
        <h2 className={styles.heading}>Tools</h2>
        <ul className={styles.toolsList}>
          <li className={styles.toolItem}>
            <h3 className={styles.toolTitle}>Windows: Proxifier</h3>
            <a href="https://www.proxifier.com/" target="_blank" rel="noopener noreferrer" className={styles.toolLink}>
              Download
            </a>
          </li>
          <li className={styles.toolItem}>
            <h3 className={styles.toolTitle}>Linux: Proxifier</h3>
            <a href="https://www.proxifier.com/" target="_blank" rel="noopener noreferrer" className={styles.toolLink}>
              Download
            </a>
          </li>
          <li className={styles.toolItem}>
            <h3 className={styles.toolTitle}>Proxifier Keys</h3>
            <a href="https://gist.github.com/f0r34chb3t4/a4f57239d76c27829066eae87fe3a8b9#file-keys-txt" target="_blank" rel="noopener noreferrer" className={styles.toolLink}>
              Get Keys
            </a>
          </li>
          <li className={styles.toolItem}>
            <h3 className={styles.toolTitle}>Android: SocksDroid</h3>
            <a href="https://shorturl.at/cpxN2" target="_blank" rel="noopener noreferrer" className={styles.toolLink}>
              Download
            </a>
          </li>
          <li className={styles.toolItem}>
            <h3 className={styles.toolTitle}>iOS: Potatso</h3>
            <a href="https://rb.gy/vvwxod" target="_blank" rel="noopener noreferrer" className={styles.toolLink}>
              Download
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Tools;
