import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/bar'; // Adjust the import path as needed
import styles from './Home.module.css';

export function Home() {
  return (
    <div><Navbar />
    <div>
    </div>
    <div className={styles.wrapper}>
        <div className={styles.textContent}>
        <p>এই ওয়েবসাইট থেকে SOCKS-5 Proxy কিনতে পারবেন BDIX-SPEED bypass করার জন্য (বিস্তারিত <Link className={styles.linlk} to="/tutorials">Tutorials</Link> পেজে দেওয়া আছে)।</p>
        <p>এই ওয়েবসাইটের বিশেষ সুবিধাসমূহ:</p>
        <ul>
          <li>Real-Time proxy generate এবং regenerate করার সুবিধা।</li>
          <li>এক ক্লিকেই proxy generate করতে পারবেন, অর্ডার করে অপেক্ষা করতে হবে না।</li>
          <li>নিজের পছন্দমতো username ও password দিয়ে proxy সেটআপ করতে পারবেন।</li>
          <li>Proxy-তে কোনো সমস্যা হলে regenerate করে server/ip পরিবর্তন করতে পারবেন(in 1 click)।</li>
          <li>24/7 Support On <Link className={styles.linlk} to="/contact">Telegram</Link>!</li>
        </ul>
          <p className={styles.ispWarning}>Warning: If Your ISP Is Dot, Amberit, Link3, Carnival, Don't Purchase From This Website.</p>
        </div>
    </div>
    </div>
  );
}

export default Home;
