import { auth, googleprovider, db } from "../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { Navigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { FaGoogle, FaArrowLeft } from "react-icons/fa";
import styles from './Login.module.css';

export const Login = () => {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.emailVerified) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (isLoggedIn) {
        return <Navigate to="/dashboard" />;
    }

    const saveUserDetails = async (uid, username, email) => {
        const userDocRef = doc(db, "users", uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid,
                username,
                email,
                balance: 0 // Initialize balance
            });
        }
    };

    const login = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            const user = userCredential.user;
            if (user.emailVerified) {
                // Get the JWT token
                const token = await user.getIdToken();
                // Store the token in localStorage
                localStorage.setItem('jwtToken', token);

                await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
                setMessage("Login successful!");
                setMessageType("success");
            } else {
                await auth.signOut();
                setMessage("Email not verified. Please check your inbox and verify your email before logging in.");
                setMessageType("error");
            }
        } catch (err) {
            console.error(err);
            setMessage("Login failed. Please check your credentials.");
            setMessageType("error");
        }
    };

    const loginWithGoogle = async () => {
        try {
            const userCredential = await signInWithPopup(auth, googleprovider);
            const user = userCredential.user;
            if (user.emailVerified) {
                await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
                // Get the JWT token
                const token = await user.getIdToken();
                // Store the token in localStorage
                localStorage.setItem('jwtToken', token);

                setMessage("Login with Google successful!");
                setMessageType("success");
            } else {
                await auth.signOut();
                setMessage("Email not verified. Please check your inbox and verify your email before logging in.");
                setMessageType("error");
            }
        } catch (err) {
            setMessage("Google login failed: " + err.message);
            setMessageType("error");
        }
    };

    return (
        <div>
            <button className={styles.backButton}><Link to="/"><FaArrowLeft /> Back</Link></button> {/* Back Button with Icon */}
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Login</h2>
                    <div className={styles.inputContainer}>
                        <input type="email" placeholder="Email" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} />
                    </div>
                    <div className={styles.inputContainer}>
                        <input type="password" placeholder="Password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
                    </div>
                    <button className={styles.button} onClick={login}>Login</button>
                    <button className={styles.buttonGoogleButton} onClick={loginWithGoogle}>
                        <FaGoogle className={styles.googleIcon} /> Sign in with Google
                    </button> {/* Google Button with Icon */}
                    {message && <p className={`${styles.message} ${styles[messageType]}`}>{message}</p>}
                    <Link to="/forgot-password" className={styles.forgotPasswordLink}>Forgot Password?</Link>
                    <Link to="/signup" className={styles.signupLink}>Don't have an account? Signup</Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
